/**
* Fix selector modal CSS
*/

.emoji-mart {
  margin-top: 10px;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  display: block;
  position: absolute;
  color: #222427;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  background: #fff;
  z-index: 1000;
}
