html {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  height: 100%;
}

body {
  font-family: "Roboto", sans-serif;
  line-height: 1.2;
  font-size: 14px;
  height: 100%;
  --webkit-overflow-scrolling: touch;
  margin: 0;
  padding: 0;
  color: #000000;
}

* {
  font-family: "Roboto", sans-serif;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

#root {
  min-height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
}

.ReactModal__Body--open,
.ReactModal__Html--open {
  overflow: hidden;
}

/** 
 * React-emoji - Emoji size 
 */
.emoji {
  width: 20px;
}

/**
* Fix selector modal CSS
*/

.emoji-mart {
  margin-top: 10px;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  display: block;
  position: absolute;
  color: #222427;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  background: #fff;
  z-index: 1000;
}

