html {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  height: 100%;
}

body {
  font-family: "Roboto", sans-serif;
  line-height: 1.2;
  font-size: 14px;
  height: 100%;
  --webkit-overflow-scrolling: touch;
  margin: 0;
  padding: 0;
  color: #000000;
}

* {
  font-family: "Roboto", sans-serif;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

#root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.ReactModal__Body--open,
.ReactModal__Html--open {
  overflow: hidden;
}

/** 
 * React-emoji - Emoji size 
 */
.emoji {
  width: 20px;
}
